<template>
  <div class="app align-items-center">
    <vue-snotify></vue-snotify>
    <div class="container mt-3">
      <img class="navbar-brand-full" src="img/logo.png" width="200" height="29" alt="Inspiring Hope and Freedom">
      <b-form class="mt-3">
        <b-card>
          <div slot="header">
            <b-row>
              <b-col sm="6">
                New Client Information
              </b-col>
            </b-row>
          </div>
          <b-row>
            <b-col sm="12">
              <b-alert variant="warning" show><strong>Hello!</strong> This intake form has already been accepted or doesn't exist.  Please contact your coach to make changes to your intake form.</b-alert>
            </b-col>
          </b-row>
        </b-card>
      </b-form>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Intake404',
  components: {},
  data () {
    return {}
  },
  methods: {

  },
  created () {

  }
}
</script>


